import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import validator from 'validator';
import {ManejoErrores} from '../componentesAltoNivel/Funciones';

class RecuperacionDeCuentaPaso1 extends Component {

    state = {
	estaAjax:false,
    };


    handleSubmit = (event) => {
	event.preventDefault();
	
	const {onSiguiente,validarFormulario,valores}=this.props;
	
	
	if(validarFormulario()){
	    this.setState({estaAjax:true});
	    const formData=new FormData();
	    formData.append("correo",valores.formCorreo);

	    //Crear usuario está fuera de las rutas seguras
	    
	    fetch("/api/recuperarUsuario", {
		headers:{
		    Authorization:"Bearer "+localStorage.getItem("token")
		},
		method: 'POST',
		body: formData,
	    })
		.then(ManejoErrores)
		.then((result) => {
		    console.log('Se encontró el usuario', result);
		    this.setState({estaAjax:false});
		    onSiguiente();
		})
		.catch((error) => {
		    alert("Ocurrió un error y no se pudo encontrar el usuario:"+error);
		    this.setState({estaAjax:false});		    
		});
	}
    }
    
    render(){

	const {
	    estaAjax
	}=this.state;

	const {
	    valores,
	    handleEntradaUsuario
	}=this.props;



	return (
	    <React.Fragment>
		<Card>
		    <CardContent>
			
			<form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
			    
			    <div>
				<TextField
				    name="formCorreo"
				    required 
				    label="Correo"
				    variant="outlined"
				    fullWidth={true} margin="normal"
				    helperText={valores.errores.formCorreo}
				    onChange={handleEntradaUsuario('formCorreo')}
				    value={valores.formCorreo}
				/>
			    </div>
			    
			    <CardActions>
				
				<Button disabled={estaAjax} variant="contained" color="primary" type="submit" >Siguiente</Button>
			    </CardActions>
			    
			    {estaAjax?<CircularProgress />:""}
			    
			</form>
		    </CardContent>			    
		</Card>
		
	    </React.Fragment>
	);
    }
};

export default RecuperacionDeCuentaPaso1;

