import React, {Component} from 'react';
import TypoGraphy from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ManejoErrores} from '../componentesAltoNivel/Funciones';
import damm from 'damm';
import validator from 'validator';

class DialogoAsociarDispositivo extends Component {

    state = {
	estaAjax:false,
	abierto:false,
	formIdDispositivo:"",
	errores: {
	    formIdDispositivo:""
	}
    };


    handleOpen = () => {
	this.setState({abierto:true});
    }

    handleClose = () => {
	this.setState({abierto:false,formIdDispositivo:"",errores:{formIdDispositivo:""}});
    }

    handleEntradaUsuario = (e) => {
	const name = e.target.name;
	const value = e.target.value;
	
	const {errores} = this.state;

	switch(name) {
	case "formIdDispositivo":
	    errores.formIdDispositivo=(validator.isInt(value))?"":"El identificador se debe componer solamente de números.";
	    if(errores.formIdDispositivo.length>0)
		break;
	    
	    errores.formIdDispositivo+=(value.length<11)?"El identificador debe tener al menos 11 digitos.":"";	    
	    errores.formIdDispositivo+=(damm.verify(value)?"":"El identificador del dispositivo es incorrecto.");
	    break;
	default:
	    break;
	}

	this.setState({errores,[name]: value},() => {
	    console.log(errores);
	});


    }


    validarFormulario = (errores) => {
	let valido = true;
	Object.values(errores).forEach(
	    (val) => {
		valido =  valido && (val.length===0);
	    }
	);

	return valido;
    }

    handleSubmit = (event) => {
	event.preventDefault();
	
	if(this.validarFormulario(this.state.errores)){
	    this.setState({estaAjax:true});
	    const formData=new FormData();
	    formData.append("idDispositivo",this.state.formIdDispositivo);

	    //Crear usuario está fuera de las rutas seguras
	    
	    fetch("/api/portal/asociarDispositivo", {
		headers:{
		    Authorization:"Bearer "+localStorage.getItem("token")
		},
		method: 'POST',
		body: formData,
	    })
		.then(ManejoErrores)
		.then((result) => {
		    console.log('Se asocio correctamente el dispositivo al usuario', result);
		    this.setState({estaAjax:false});
		    const {actualizarCambios}=this.props;
		    this.handleClose();
		    actualizarCambios();
		})
		.catch((error) => {
		    alert("Ocurrió un error y no se pudo asociar el dispositivo al usuario:"+error);
		    this.setState({estaAjax:false});
		});
	}
    }

    
    render() {

	const {estaAjax,abierto,formIdDispositivo,errores}=this.state;
	
	return (<React.Fragment>
		    <Button variant="contained" color="primary" onClick={this.handleOpen}> Asociar un nuevo dispositivo al usuario</Button>
		    <Dialog open={abierto} onClose={this.handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Asociar dispositivo</DialogTitle>
			<DialogContent>
			    <DialogContentText>
				Para asociar un nuevo dispositivo a la cuenta, se debe ingresar el identificador del dispositivo.
			    </DialogContentText>

			    <TextField
				name="formIdDispositivo"
				required 
				label="Id del dispositivo"
				variant="outlined"
				fullWidth={true} margin="normal"
				helperText={errores.formIdDispositivo}
				onChange={this.handleEntradaUsuario}
				value={formIdDispositivo}
			    />

			    {estaAjax?<CircularProgress />:""}			    
			    
			</DialogContent>
			<DialogActions>
			    <Button onClick={this.handleSubmit} color="primary">
				Asociar
			    </Button>
			    <Button onClick={this.handleClose} color="secondary">
				Cancelar
			    </Button>
			</DialogActions>

		    </Dialog>
		</React.Fragment>);
    }
}

export default DialogoAsociarDispositivo;
