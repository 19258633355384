import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import ListadoFirmware from './ListadoFirmware';
import AgregarFirmware from './AgregarFirmware';

class AdministrarFirmware extends Component {

    state = {
	firmwares: []
    };


    getFirmwares() {
	fetch("/api/portal/firmwares",
	      {
		  headers:{
		      Authorization:"Bearer "+localStorage.getItem("token")
		  }
	      }
)
	    .then(res=>res.json())
	    .then((resultado) => {
		
		this.setState({
		    firmwares:resultado
		});
		
	    },
		  (error) => {
		      console.log("Ocurrio un error al obtener los firmwares");
		  });

	
    }

    componentDidMount(){
	this.getFirmwares();
    }
    
    render() {

	const {firmwares} = this.state;
	
	return (
	    <Container>
		<Grid container spacing={3}>
		    <Grid item xs={12}>
			<Paper>
			    <ListadoFirmware firmwares={firmwares} />
			    <AgregarFirmware actualizarCambios={()=>{this.getFirmwares()}}/>
			</Paper>
		    </Grid>
		</Grid>
	    </Container>
	);
    }
};

export default AdministrarFirmware;
