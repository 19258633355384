import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import validator from 'validator';
import manejoErrores from '../componentesAltoNivel/Funciones'
import RecuperacionDeCuentaPaso1 from './RecuperacionDeCuentaPaso1';
import RecuperacionDeCuentaPaso2 from './RecuperacionDeCuentaPaso2';
import RecuperacionDeCuentaPaso3 from './RecuperacionDeCuentaPaso3';

class RecuperacionDeCuenta extends Component {

    //El estado 0 es el ingreso del correo
    //El estado 1 es el ingreso del código que se envió al correo
    //El estado 2 es el ingreso de una nueva contraseña
    //El estado 3 es el mensaje de éxito
    
    state = {
	estadoFlujo:0,
	estaAjax:false,
	formCorreo:"",
	formCodigo:"",
	formClave:"",
	formClaveConfirmacion:"",
	errores: {
	    formCorreo:"",
	    formCodigo:"",
	    formClave:"",
	    formClaveConfirmacion:"",
	}
    };

    handleEntradaUsuario= (nombre) => (e) => {
	
	const name = nombre; //e.target.name;
	const value = e.target.value;
	
	const {errores} = this.state;

	switch(name) {
	case "formCorreo":
	    errores.formCorreo= validator.isEmail(value)? "":"Se debe ingresar un correo válido.";
	    break;
	case "formCodigo":
	    errores.formCodigo= (value.length!==6)?"El código debe tener 6 caracteres":"";
	    break;
	default:
	    break;
	}
	
	this.setState({errores,[name]: value},() => {
	    console.log(errores);
	});
    }
    
    validarFormulario = () => {
	let valido = true;
	let errores = this.state.errores;
	
	Object.values(errores).forEach(
	    (val) => {
		valido =  valido && (val.length===0);
	    }
	);
	
	return valido;
    }

    siguiente = () => {
	this.setState({estadoFlujo:(this.state.estadoFlujo+1)});
    }


    render(){

	const {
	    estadoFlujo,
	    estaAjax,
	    formCorreo,
	    formCodigo,
	    formClave,
	    formClaveConfirmacion,
	    errores
	}=this.state;

	const valores={formCorreo,formCodigo,formClave,formClaveConfirmacion,errores};

	switch(estadoFlujo){
	case 0:
	    return (<RecuperacionDeCuentaPaso1 valores={valores}
					       onSiguiente={this.siguiente}
					       handleEntradaUsuario={this.handleEntradaUsuario}
					       validarFormulario={this.validarFormulario}
		    />);
	case 1:
	    return (<RecuperacionDeCuentaPaso2 valores={valores}
					       onSiguiente={this.siguiente}
					       handleEntradaUsuario={this.handleEntradaUsuario}
					       validarFormulario={this.validarFormulario}
		    />);
	case 2:
	    return (<RecuperacionDeCuentaPaso3 valores={valores}
					       onSiguiente={this.siguiente}
					       handleEntradaUsuario={this.handleEntradaUsuario}
					       validarFormulario={this.validarFormulario}
		    />);
	case 3:
	    return <div> Se ha cambiado con éxito la contraseña. </div>;
					       
	default:
	    break;
	}
    }  
};

export default RecuperacionDeCuenta;

