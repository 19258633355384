import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import {ManejoErrores} from '../componentesAltoNivel/Funciones';

class ValidarCorreo extends Component {

    state = {
	confirmando:true
    };


    
    componentDidMount(){
	const { uuid } = this.props.match.params
	
	const formData=new FormData();
	formData.append("uuid",uuid);
	
	fetch("/api/validarCorreo", {
	    headers:{
		Authorization:"Bearer "+localStorage.getItem("token")
	    },
	    method: 'POST',
	    body: formData,
	})
	    .then(ManejoErrores)
	    .then((result) => {
		console.log('Se validó correctamente el correo electrónico', result);
		this.setState({confirmando:false});
	    })
	    .catch((error) => {
		alert("Ocurrió un error y no se pudo crear el usuario:"+error);
	    });
	
    }

    render(){
	
	
	if(this.state.confirmando) {
	
	    return (
		<React.Fragment>
		    <Card>
			Confirmando correo electrónico.
			<CardContent>
			    <CircularProgress />
			</CardContent>			    
		    </Card>
		    
		</React.Fragment>
	    );
	} else {
	    
	    return (
		<React.Fragment>
		    <Card>
			<CardContent>
			    Su correo electrónico ha sido confirmado con éxito. 
			</CardContent>			    
		    </Card>
		    
		</React.Fragment>
	    );
	}
    }
};

export default withRouter(ValidarCorreo);

