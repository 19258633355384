import React, {Component} from 'react';
import TypoGraphy from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

class ListadoFirmware extends Component {
   
    render() {

	const {firmwares} = this.props;
	
        return (
	    <React.Fragment>	    
		<TypoGraphy component="h2" variant="h6" color="primary">
		    Listado de firmwares.
		</TypoGraphy>
		
		<Table size="small">
		    <TableHead>
			<TableRow>
			    <TableCell>Id Firmware</TableCell>
			    <TableCell>Version</TableCell>
			    <TableCell>Modelo</TableCell>
			    <TableCell>Fecha de creación</TableCell>
			    <TableCell>Es para pruebas?</TableCell>			    
			    <TableCell>Acciones</TableCell>
			</TableRow>
		    </TableHead>
		    <TableBody>
			{firmwares.map((f) => (
			    <TableRow key={f.IdFirmware}>
				<TableCell>{f.IdFirmware}</TableCell>				
				<TableCell>{f.Version}</TableCell>
				<TableCell>{f.Modelo}</TableCell>
				<TableCell>{f.FechaDeCreacion}</TableCell>
				<TableCell>{(f.EsParaPruebas)?"Si":"No"}</TableCell>
				<TableCell><Button variant="outlined" onClick={()=> {}}>Editar</Button></TableCell>
			    </TableRow>
			))}
		    </TableBody>
		</Table>
            </React.Fragment>

	);
    }
};

export default ListadoFirmware;
