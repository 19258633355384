import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import validator from 'validator';
import {ManejoErrores} from '../componentesAltoNivel/Funciones';
import {withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TypoGraphy from '@material-ui/core/Typography';


class CreacionDeCuenta extends Component {

    //El estado 0 es la creación de la cuenta
    //El estado 1 es el mensaje de que se envió un correo y el próximo paso es hacer click en un link que se envió para validar el correo
    //El sistema deberá borrar las cuentas que quedaron sin validación de correo.
    

    state = {
	estadoFlujo:0,
	estaAjax:false,
	formNombre:"",
	formCorreo:"",
	formCorreoConfirmacion:"",	
	formClave:"",
	formNombreOrganizacion:"",
	formTelefonoPersonal:"",
	formTelefonoTrabajo:"",
	errores: {
	    formNombre:"",
	    formCorreo:"",
	    formCorreoConfirmacion:"",	
	    formClave:"",
	    formNombreOrganizacion:"",
	    formTelefonoPersonal:"",
	    formTelefonoTrabajo:""
	}
    };


    handleEntradaUsuario=  (e) => {
	
	const name = e.target.name;
	const value = e.target.value;
	
	const {errores} = this.state;

	switch(name) {
	case "formNombre":
	    errores.formNombre= (value.length<3) ? "El nombre debe tener más de tres carácteres.":"";
	    break;
	case "formCorreo":
	    errores.formCorreo= validator.isEmail(value)? "":"Se debe ingresar un correo válido.";
	    break;
	case "formCorreoConfirmacion":
	    errores.formCorreoConfirmacion= (this.state.formCorreo===value)?"":"El correo de confirmación debe ser igual al correo ingresado previamente.";
	    break;
	case "formClave":
	    errores.formClave=(value.length<8)?"La contraseña debe tener al menos 8 caracteres.":"";
	    break;
	case "formNombreOrganizacion":
	    break;
	case "formTelefonoPersonal":
	    break;
	case "formTelefonoTrabajo":
	    break;
	default:
	    break;
	}

	this.setState({errores,[name]: value},() => {
	    console.log(errores);
	});
    }


    handleCancelar = () => {
	const {setLogeado,history}=this.props;	
	history.push("/");
    }

    validarFormulario = (errores) => {
	let valido = true;
	Object.values(errores).forEach(
	    (val) => {
		valido =  valido && (val.length===0);
	    }
	);

	return valido;
    }
    
    
    handleSubmit = (event) => {
	event.preventDefault();
	
	if(this.validarFormulario(this.state.errores)){
	    this.setState({estaAjax:true});
	    const formData=new FormData();
	    formData.append("nombre",this.state.formNombre);
	    formData.append("correo",this.state.formCorreo);
	    formData.append("clave",this.state.formClave);
	    formData.append("nombreOrganizacion",this.state.formNombreOrganizacion);
	    formData.append("telefonoPersonal",this.state.formTelefonoPersonal);
	    formData.append("telefonoTrabajo",this.state.formTelefonoTrabajo);

	    //Crear usuario está fuera de las rutas seguras
	    
	    fetch("/api/crearUsuario", {
		headers:{
		    Authorization:"Bearer "+localStorage.getItem("token")
		},
		method: 'POST',
		body: formData,
	    })
		.then(ManejoErrores)
		.then((result) => {
		    console.log('Se creo correctamente el usuario', result);
		    this.setState({estadoFlujo:1,estaAjax:false});
		})
		.catch((error) => {
		    alert("Ocurrió un error y no se pudo crear el usuario:"+error);
		    this.setState({estaAjax:false});		    
		});
	}
    }
    
    render(){

	const {
	    estadoFlujo,
	    estaAjax,
	    formNombre,
	    formCorreo,
	    formCorreoConfirmacion,	
	    formClave,
	    formNombreOrganizacion,
	    formTelefonoPersonal,
	    formTelefonoTrabajo,
	    errores}=this.state;


	if(estadoFlujo===0) {
	
	    return (
		<Container>
		    <Grid container spacing={3}>
			<Grid item xs={12} md={12} >
			    <Card>
				<CardContent>

				    <TypoGraphy component="h2" variant="h6" color="primary">
					Creación de cuenta
				    </TypoGraphy>
				    
				    
				    <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
					
					<div>
					    <TextField
						name="formNombre"
						required 
						label="Nombre"
						variant="outlined"
						fullWidth={true} margin="normal"
						helperText={errores.formNombre}
						onChange={this.handleEntradaUsuario}
						value={formNombre}
						
					    />
					</div>
					
					<div>
					    <TextField
						name="formCorreo"
						required 
						label="Correo"
						variant="outlined"
						fullWidth={true} margin="normal"
						helperText={errores.formCorreo}
						onChange={this.handleEntradaUsuario}
						value={formCorreo}
					    />
					</div>
					
					<div>
					    <TextField
						name="formCorreoConfirmacion"
						required 
						label="Confirmación de correo"
						variant="outlined"
						fullWidth={true} margin="normal"
						helperText={errores.formCorreoConfirmacion}
						onChange={this.handleEntradaUsuario}
						value={formCorreoConfirmacion}
					    />
					</div>
					
					<div>
					    <TextField
						name="formClave"
						required 
						label="Contraseña"
						type="password"
						variant="outlined"
						fullWidth={true} margin="normal"
						helperText={errores.formClave}
						onChange={this.handleEntradaUsuario}
						value={formClave}
					    />
					</div>
					
					<div>
					    <TextField
						name="formNombreOrganizacion"
						label="Organización"
						variant="outlined"
						fullWidth={true} margin="normal"
						helperText={errores.formNombreOrganizacion}
						onChange={this.handleEntradaUsuario}
						value={formNombreOrganizacion}
					    />
					</div>
					
					<div>
					    <TextField
						name="formTelefonoPersonal"
						label="Teléfono personal"
						variant="outlined"
						fullWidth={true} margin="normal"
						helperText={errores.formTelefonoPersonal}
						onChange={this.handleEntradaUsuario}
						value={formTelefonoPersonal}
					    />
					</div>
					
					<div>
					    <TextField
						name="formTelefonoTrabajo"
						label="Teléfono trabajo"
						variant="outlined"
						fullWidth={true} margin="normal"
						helperText={errores.formTelefonoTrabajo}
						onChange={this.handleEntradaUsuario}
						value={formTelefonoTrabajo}
					    />
					</div>
					
					<CardActions>
					    
					    <Button disabled={estaAjax} variant="contained" color="primary" type="submit" >Guardar</Button>
					    <Button disabled={estaAjax} variant="contained" color="secondary" onClick={this.handleCancelar}>Cancelar</Button>
					</CardActions>
					
					{estaAjax?<CircularProgress />:""}
					
				    </form>
				</CardContent>			    
			    </Card>
			</Grid>
		    </Grid>
		</Container>
	    );
	} else if(estadoFlujo===1) {
	    return (
		<Container>
		    <Grid container spacing={3}>
			<Grid item xs={12} md={12} >
			    <Card>
				<CardContent>
				    Se envió un link a su correo para que valide la existencia de dicho correo. Una vez realizado dicho paso, podrá ingresar al sistema.
				</CardContent>
			    </Card>
			</Grid>
		    </Grid>
		</Container>
	    );

	}
    }
};

export default withRouter(CreacionDeCuenta);

