import logo from './iotwits-logo.svg';
import './App.css';
import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TypoGraphy from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

import MisDispositivos from './misDispositivos/MisDispositivos';
import AdministrarFirmware from './administrarFirmware/AdministrarFirmware';
import Login from './login/Login';
import CreacionDeCuenta from './usuarios/CreacionDeCuenta';
import RecuperacionDeCuenta from './usuarios/RecuperacionDeCuenta';
import ValidarCorreo from './usuarios/ValidarCorreo';
import RutaProtegida from './componentesAltoNivel/RutaProtegida';
import jwt_decode from "jwt-decode";

import { Link as RouterLink,
	 Switch,
	 Route,
	 Redirect,
	 withRouter,
       } from "react-router-dom";


const useStyles = theme => ({
    offset: theme.mixins.toolbar,
    toolbar: {
	display: "flex",
	justifyContent: "space-between",
    },
    menuButton: {
	marginLeft: "38px",
    }
});

class App extends Component {

    state = {
	estaLogeado:false,
	rol:""
    }

    estaValidoElToken = () => {
	if(localStorage.hasOwnProperty("token")) {
	    const token = localStorage.getItem("token");
	    try {
		const date = new Date(0);
		const decoded = jwt_decode(token);
		date.setUTCSeconds(decoded.exp);
		const retorno=date.valueOf() > new Date().valueOf();
		return retorno;
	    } catch (err) {
		return false;
	    }
	} else {
	    return false;
	}
    };


    componentDidMount() {
	this.setState({estaLogeado:this.estaValidoElToken()});
    }


    handleLogout= (event) => {
	const {history}=this.props;
	event.preventDefault();
	localStorage.removeItem("token");
	history.push("/");
	this.setState({estaLogeado:false});
    }
    
    render() {

	const { classes } = this.props;
	const {estaLogeado,rol}=this.state;
	
        return (
            <div className="App">
		<AppBar color="primary" position="static"  style={{ backgroundColor: '#f6f6f6',color:'#3c2d73' }} >
		    <Toolbar className={classes.toolbar}>
			<img src={logo} className="App-logo" alt="logo" />

			<div>

			    {estaLogeado?
			     <Button
				 {...{
				     color: "inherit",
				     to: "/misDispositivos",
				     component: RouterLink,
				     className: classes.menuButton,
				 }}
			     >
				 Mis Dispositivos
			     </Button>:""}

			    {(estaLogeado&&(rol==="SuperAdmin"))?
			     <Button
				 {...{
				     color: "inherit",
				     to: "/admFirmware",
				     component: RouterLink,
				     className: classes.menuButton,				    
				 }}
			     >
				 Administrar Firmware
			     </Button>:""}

			    
			    			     
			    {estaLogeado?
			    <Button

				
				
				{...{
				    color: "secondary",
				    to: "/",
				    component: RouterLink,
				    className: classes.menuButton,				    
				}}

				onClick={this.handleLogout}
			    >
				Logout
			    </Button>:
			     <Button
				 
				 
				 
				 {...{
				     color: "secondary",
				     to: "/",
				     component: RouterLink,
				     className: classes.menuButton,				    
				 }}
			     >
				 Login
			     </Button>
			    }
			     
			    
			    
			    
			</div>
		    </Toolbar>
		</AppBar>
		<div className={classes.offset} />


		<Switch>
		    
		    <Route exact
			   path="/"
			   render={() => {
			       return (
				   <Redirect to="/misDispositivos" />
			       )
			   }}
		    />

		    <Route path="/creacionDeCuenta">
			<CreacionDeCuenta/>
		    </Route>

		    <Route path="/recuperacionDeCuenta">
			<RecuperacionDeCuenta/>
		    </Route>

		    <Route path="/validarCorreo/:uuid">
			<ValidarCorreo/>
		    </Route>

		    
		    <Route path="/login">
			<Login setLogeado={(nuevoRol)=>{this.setState({estaLogeado:true,rol:nuevoRol})}}/>
		    </Route>
		    
		    <RutaProtegida estaAutenticado={estaLogeado} path="/misDispositivos" component={MisDispositivos}/>
		    <RutaProtegida estaAutenticado={estaLogeado} path="/admFirmware" component={AdministrarFirmware}/>
		    
		</Switch>

		<Box pt={4}>
		    <TypoGraphy variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://www.iotwits.com/">
			    IOT Wits
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		    </TypoGraphy>
		</Box>
		
            </div>
        );
    }

}


export default withRouter(withStyles(useStyles)(App));
