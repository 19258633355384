import React from 'react'
import { Redirect } from 'react-router-dom'


class RutaProtegida extends React.Component {
    
    
    render() {
        const Component = this.props.component;
        const {estaAutenticado} = this.props;
       
        return estaAutenticado ? (
            <Component />
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
    }
}

export default RutaProtegida;
