import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import MenuListadoDispositivos from './MenuListadoDispositivos';
import DatosDelDispositivo from './DatosDelDispositivo';
import {ManejoErrores} from '../componentesAltoNivel/Funciones';
import damm from 'damm';

//TODO: Agregar spinner cuando se está generando el excel
//TODO: Agregar spinner cuando se está cargando el gráfico
//TODO: Mostrar solo los dispositivos asociados al usuario en particular, para los usuarios cliente. El usuario superadministrador puede ver todos los dispositivos.


class MisDispositivos extends Component {

    state = {
	estaAjax:false,
	dispositivos: [	],
	dispositivoSeleccionado: null
    };


    getDispositivos() {

	this.setState({estaAjax:true});
	
	fetch("/api/portal/dispositivos",{
	    headers:{
		Authorization:"Bearer "+localStorage.getItem("token")
	    }
	})
	    .then(ManejoErrores)
	    .then((resultado) => {

		//Reemplazo todos los id por el id con damm
		for(var i in resultado){
		    resultado[i].IdFisicoDispositivo=damm.append(resultado[i].IdHardware.slice(4));
		}
		
		this.setState({
		    dispositivos:resultado,estaAjax:false
		});
	    },
		  (error) => {
		      this.setState({estaAjax:false});
		      console.log("Ocurrió un error al obtener los dispositivos");
		  });
    };

    setDispositivoSeleccionado=(idDispositivo) => {
	this.setState({
	    dispositivoSeleccionado:idDispositivo
	});
    }
    
    

    componentDidMount() {
	//Acá consulto la API para obtener los dispositivos.
	this.getDispositivos();
	
    }

    
    render() {
	const {estaAjax,dispositivos,dispositivoSeleccionado}=this.state;

	return (
	    <Container>
		<Grid container spacing={3}>
		    <Grid item xs={12} md={12} >
			<MenuListadoDispositivos setDispositivoSeleccionado={this.setDispositivoSeleccionado} dispositivos={dispositivos} actualizarCambios={()=>{this.getDispositivos()}} estaAjax={estaAjax} />
		    </Grid>
		</Grid>
		
		
		<DatosDelDispositivo dispositivo={dispositivoSeleccionado}/>
		
		
	    </Container>
	);
    }
};

export default MisDispositivos;
