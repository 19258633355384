import React, {Component} from 'react';
import TypoGraphy from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import DialogoAsociarDispositivo from './DialogoAsociarDispositivo';
import DialogoEditarDispositivo from './DialogoEditarDispositivo';

class MenuListadoDispositivos extends Component {
    render() {
	
	const {dispositivos,setDispositivoSeleccionado,actualizarCambios,estaAjax}=this.props;
	
        return (
	    <Card>
		<CardContent>
		    
		    <TypoGraphy component="h2" variant="h6" color="primary">
			Mis dispositivos
		    </TypoGraphy>
		    
		    <Table size="small">
			<TableHead>
			    <TableRow>
				<TableCell>Id Dispositivo</TableCell>
				<TableCell>Versión Firmware</TableCell>
				<TableCell>Ubicación</TableCell>			    
				<TableCell>Acciones</TableCell>
			    </TableRow>
			</TableHead>
			<TableBody>
			    {dispositivos.map((d) => (
				<TableRow key={d.IdDispositivo}>
				    <TableCell>{d.IdFisicoDispositivo}</TableCell>
				    <TableCell>{d.VersionFirmware.String}</TableCell>				
				    <TableCell>{d.Ubicacion.String}</TableCell>
				    <TableCell>
					
					<DialogoEditarDispositivo dispositivo={d} actualizarCambios={actualizarCambios}/>
					
					<Button variant="contained" color="primary" onClick={()=> {setDispositivoSeleccionado(d.IdDispositivo)}}>Ver información</Button>
				    </TableCell>
				</TableRow>
			    ))}
			</TableBody>
		    </Table>

		    {estaAjax?<CircularProgress />:""}
		    
		</CardContent>
		
		<CardActions>
		    <DialogoAsociarDispositivo actualizarCambios={actualizarCambios}/>
		</CardActions>
		
	    </Card>
        );
    }
}


export default MenuListadoDispositivos;
