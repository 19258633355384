import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';



const theme = createMuiTheme({
    palette: {
	primary: {
	    main: "#3c2d73"
	},
	secondary: {
	    main: "#85b929"
	}
    }});

			     
ReactDOM.render(
    <React.StrictMode>
	<BrowserRouter>
	    <MuiPickersUtilsProvider utils={MomentUtils}>
		<MuiThemeProvider theme={theme}>
		    <App />
		</MuiThemeProvider>
	    </MuiPickersUtilsProvider>
	</BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
