import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import TypoGraphy from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Redirect,Link,withRouter } from "react-router-dom";
import {ManejoErrores,ParseJwt} from '../componentesAltoNivel/Funciones'


class Login extends Component {

    state = {
	estaAjax:false,
	seAutentico:false,
	formCorreoElectronico:"",
	formContrasenna:"",
	errores: {
	    formCorreoElectronico:"",
	    formContrasenna:""
	}
    };
    
    handleSubmit = (event) => {
	const {setLogeado,history}=this.props;
	
	event.preventDefault();

	if(this.validarFormulario(this.state.errores)){
	    this.setState({estaAjax:true});
	    const formData=new FormData();
	    formData.append("username",this.state.formCorreoElectronico);
	    formData.append("password",this.state.formContrasenna);

	    
	    fetch("/api/loginPortal", {
		method: 'POST',
		body: formData,
	    })
		.then(ManejoErrores)
		.then((result) => {
		    console.log('Se autenticó correctamente.', result);
		    localStorage.setItem("token",result.token);
		    this.setState({seAutentico:true,estaAjax:false});
		    var tokenParseado=ParseJwt(result.token);
		    setLogeado(tokenParseado.rol);
		    history.push("/");
		    
		})
		.catch((error) => {
		    alert("Permiso denegado:"+error);
		    this.setState({estaAjax:false});
		});
	    
	}
	
    };

    handleBorrar = (event) => {
	event.preventDefault();
	
	this.setState({formCorreoElectronico:"",formContrasenna:""});
	
    };

    handleEntradaUsuario=  (e) => {

	const name = e.target.name;
	const value = e.target.value;

	const {errores} = this.state;

	switch(name) {
	case "formCorreoElectronico":
	    
	    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    errores.formCorreoElectronico= (!re.test(value)) ? "El correo electrónico es incorrecto":"";
	    
	    break;
	case "formContrasenna":
	    errores.formContrasenna= (value.length===0) ? "La contraseña es obligatoria":"";
	    break;
	default:
	    break;
	}

	this.setState({errores,[name]: value},() => {
	    console.log(errores);
	});

	
    }
    

    validarFormulario = (errores) => {
	let valido = true;
	Object.values(errores).forEach(
	    (val) => {
		valido =  valido && (val.length===0);
	    }
	);
	return valido;
    }
    

    render() {

	const {seAutentico,
	       estaAjax,
	       formCorreoElectronico,
	       formContrasenna,
	       errores}=this.state;
	

	if(seAutentico) {

	    return (<Redirect to="/" />);

	} else {
	    
	    return (<Container>
			<Grid container spacing={3}>
			    <Grid item xs={12} md={6}>
				<Card>
				    <CardContent>
					<TypoGraphy component="h2" variant="h6" color="primary">
					    Ingreso al sistema
					</TypoGraphy>
					
					<form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
					    
					    <div>
						<TextField
						    name="formCorreoElectronico"
						    required 
						    label="Correo electrónico"
						    variant="outlined"
						    fullWidth={true} margin="normal"
						    helperText={errores.formCorreoElectronico}
						    onChange={this.handleEntradaUsuario}
						    value={formCorreoElectronico}
						/>
					    </div>
					    
					    <div>
						<TextField
						    name="formContrasenna"
						    required
						    label="Contraseña"
						    variant="outlined"
						    fullWidth={true} margin="normal"
						    helperText={errores.formContrasenna}
						    onChange={this.handleEntradaUsuario}
						    value={formContrasenna}
						    type="password"
						/>
					    </div>
					    <CardActions>
						
						<Button variant="contained" color="primary" type="submit" disabled={estaAjax}>Ingresar al sistema</Button>
						<Button variant="contained" color="secondary" onClick={this.handleBorrar}>Borrar</Button>
						{estaAjax?<CircularProgress />:""}

					    </CardActions>
					    
					</form>
				    </CardContent>			    
				</Card>

			    </Grid>


			    <Grid item xs={12} md={6}>
				<Card>
				    <CardContent>
					<TypoGraphy component="h2" variant="h6" color="primary">
					    Recuperación de contraseña
					</TypoGraphy>
					
					
					Si olvidó su contraseña, puede recuperarla haciendo <Link to="/recuperacionDeCuenta">click aquí.</Link>
 				    </CardContent>
				</Card>
			    </Grid>
				
				
			    <Grid item xs={12} md={6}>
				<Card>
				    <CardContent>
					<TypoGraphy component="h2" variant="h6" color="primary">
					    Creación de cuenta
					</TypoGraphy>
					
					Si usted no posee una cuenta, se puede crear una haciendo <Link to="/creacionDeCuenta">click aquí</Link>. Al crearse una cuenta, usted podrá asociar dispositivos a ella, visualizar la información, definir alertas, entre otras funcionalidades.
 				    </CardContent>
				</Card>
			    </Grid>
			</Grid>
		    </Container>);
	}
	
    }
};

export default withRouter(Login);
