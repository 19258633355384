import React, {Component} from 'react';
import TypoGraphy from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ManejoErrores} from '../componentesAltoNivel/Funciones';
import damm from 'damm';
import validator from 'validator';

class DialogoEditarDispositivo extends Component {

    state = {
	estaAjax:false,
	abierto:false,
	formUbicacion:this.props.dispositivo.Ubicacion.String,
	errores: {
	    formUbicacion:""
	}
    };


    handleOpen = () => {
	this.setState({abierto:true});
    }

    limpiarCampos = () => {
	this.setState({formUbicacion:"",
		       errores: {
			   formUbicacion:""
		       }});
    }
    
    handleClose = () => {
	this.limpiarCampos();
	this.setState({abierto:false});
    }

    handleEntradaUsuario = (e) => {
	const name = e.target.name;
	const value = e.target.value;
	
	const {errores} = this.state;

	switch(name) {
	case "formUbicacion":
	    errores.formUbicacion=(value.length<1)?"La ubicación debe tener al menos una carácter.":"";	    
	    break;
	default:
	    break;
	}

	this.setState({errores,[name]: value},() => {
	    console.log(errores);
	});


    }


    validarFormulario = (errores) => {
	let valido = true;
	Object.values(errores).forEach(
	    (val) => {
		valido =  valido && (val.length===0);
	    }
	);

	return valido;
    }

    handleSubmit = (event) => {
	const {dispositivo}=this.props;
	const {formUbicacion}=this.state;
	
	event.preventDefault();
	
	if(this.validarFormulario(this.state.errores)){
	    this.setState({estaAjax:true});
	    const formData=new FormData();
	    formData.append("idHardware",dispositivo.IdHardware);
	    formData.append("ubicacion",formUbicacion);

	    //Crear usuario está fuera de las rutas seguras
	    
	    fetch("/api/portal/editarDispositivo", {
		headers:{
		    Authorization:"Bearer "+localStorage.getItem("token")
		},
		method: 'POST',
		body: formData,
	    })
		.then(ManejoErrores)
		.then((result) => {
		    console.log('Se edito correctamente el dispositivo', result);
		    this.setState({estaAjax:false});
		    const {actualizarCambios}=this.props;
		    this.handleClose();
		    actualizarCambios();
		})
		.catch((error) => {
		    alert("Ocurrió un error y no se pudo editar el dispositivo"+error);
		    this.setState({estaAjax:false});
		});
	}
    }

    
    render() {

	const {estaAjax,abierto,formUbicacion,errores}=this.state;
	const {dispositivo}=this.props;
	
	return (<React.Fragment>
		    <Button variant="contained" color="primary" onClick={this.handleOpen}>Editar</Button>
		    <Dialog open={abierto} onClose={this.handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Editar información del dispositivo</DialogTitle>
			<DialogContent>
			    <DialogContentText>
				A continuación se pueden editar los datos del dispositivo {dispositivo.IdFisicoDispositivo}
			    </DialogContentText>

			    <TextField
				name="formUbicacion"
				required 
				label="Ubicacion del dispositivo"
				variant="outlined"
				fullWidth={true} margin="normal"
				helperText={errores.formUbicacion}
				onChange={this.handleEntradaUsuario}
				value={formUbicacion}
			    />

			    {estaAjax?<CircularProgress />:""}			    
			    
			</DialogContent>
			<DialogActions>
			    <Button onClick={this.handleSubmit} color="primary">
				Guardar
			    </Button>
			    <Button onClick={this.handleClose} color="secondary">
				Cancelar
			    </Button>
			</DialogActions>

		    </Dialog>
		</React.Fragment>);
    }
}

export default DialogoEditarDispositivo;
