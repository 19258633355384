import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
//import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DateTimePicker } from "@material-ui/pickers";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

class AgregarFirmware extends Component {

    state = {
	estaEditando:false,
	estaSeleccionadoElArchivo:false,
	formVersion:"",
	formModelo:"",
	formFechaDeCreacion:new Date(),
	formEsParaPruebas:true,
	formArchivo:"",
	errores: {
	    formVersion:"",
	    formModelo:"",
	    formFechaDeCreacion:"",
	    formEsParaPruebas:"",
	    formArchivo:""
	}
    };


    handleAgregar = (event) => {
	event.preventDefault();	
	this.setState({estaEditando:true});
    }

    handleCancelar = (event) => {
	event.preventDefault();	
	this.setState({estaEditando:false});
    }

    handleSeleccionArchivo= (evento) => {
	evento.preventDefault();
	this.setState({formArchivo:evento.target.files[0],
		      estaSeleccionadoElArchivo:true
		      });
    }
    
    handleEntradaUsuario=  (e) => {

	const name = e.target.name;
	const value = e.target.value;

	const {errores} = this.state;

	switch(name) {
	case "formVersion":
	    errores.formVersion= (value.length<3) ? "La versión es obligatoria":"";
	    break;
	case "formModelo":
	    errores.formModelo= (value.length===0) ? "El modelo es obligatorio":"";
	    break;
	case "formFechaDeCreacion":
	    
	    break;
	case "formEsParaPruebas":
	    
	    break;
	default:
	    break;
	}

	this.setState({errores,[name]: value},() => {
	    console.log(errores);
	});
    }


    validarFormulario = (errores) => {
	let valido = true;
	Object.values(errores).forEach(
	    (val) => {
		valido =  valido && (val.length===0);
	    }
	);
	//Si los campos están correctos y está seleccionado un archivo.
	return valido && this.state.estaSeleccionadoElArchivo;
    }

    
    handleSubmit = (event) => {
	event.preventDefault();

	if(this.validarFormulario(this.state.errores)){
	    const formData=new FormData();

	    formData.append("archivo",this.state.formArchivo);
	    formData.append("version",this.state.formVersion);
	    formData.append("modelo",this.state.formModelo);
	    formData.append("fechaDeCreacion",this.state.formFechaDeCreacion.toISOString());
	    formData.append("esParaPruebas",this.state.formEsParaPruebas);
	    
	    fetch("/api/portal/agregarFirmware", {
		headers:{
		    Authorization:"Bearer "+localStorage.getItem("token")
		},
		method: 'POST',
		body: formData,
	    })
		.then((response) => response.json())
		.then((result) => {
		    console.log('Se subió correctamente el archivo.', result);
		    this.setState({estaEditando:false});
		    const {actualizarCambios}=this.props;
		    actualizarCambios();
		})
		.catch((error) => {
		    alert("Ocurrió un error y no se pudo subir el archivo:"+error);
		});
	    

	}

    }
    
    render(){
	const {estaEditando,estaSeleccionadoElArchivo,
	       formVersion,formModelo,formFechaDeCreacion,formEsParaPruebas,
	       formArchivo,errores}=this.state;

	if (estaEditando) {

	    return (
		<React.Fragment>
		    <Card>
			<CardContent>
			    
			    <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>

				<div>
				    <TextField
					name="formVersion"
					required 
					label="Version"
					variant="outlined"
					fullWidth={true} margin="normal"
					helperText={errores.formVersion}
					onChange={this.handleEntradaUsuario}
					value={formVersion}

				    />
				</div>
				
				<div>
				    <TextField
					name="formModelo"
					required
					label="Modelo"
					defaultValue="sensorCo2-1.0"
					variant="outlined"
					fullWidth={true} margin="normal"
					helperText={errores.formModelo}
					onChange={this.handleEntradaUsuario}
					value={formModelo}
				    />
				</div>

				<div>
				    <DateTimePicker
					name="formFechaDeCreacion"
					required 
					value={formFechaDeCreacion}
					disablePast
					label="Fecha de creación"
					showTodayButton
					variant="outlined"
					fullWidth={true}
					margin="normal"
						    
				    />
				    
				</div>

				<div>
				    <FormControlLabel
					control={
					    <Checkbox
						name="esParaPruebas"
						color="primary"
						value={formEsParaPruebas}
					    />
					}
					label="Es para pruebas?"
				    />
				</div>

				<div>
				    
				    <Button
					variant="contained"
					component="label"
					startIcon={<CloudUploadIcon />}
				    >
					Seleccionar archivo firmware
					<input
					    type="file"
					    onChange={this.handleSeleccionArchivo}
					    hidden
					/>
				    </Button>

				</div>

				{estaSeleccionadoElArchivo?
				 <div>
				     <p>Archivo:{formArchivo.name}</p>
				     <p>Tamaño:{formArchivo.size}</p>
				 </div>:""}
						
				<CardActions>
				    
				    <Button variant="contained" color="primary" type="submit" >Guardar</Button>
				    <Button variant="contained" color="secondary" onClick={this.handleCancelar}>Cancelar</Button>
				</CardActions>

				</form>
			</CardContent>			    
		    </Card>
			    
		</React.Fragment>
	    );
	    

	} else 	{
	    return (
		<Button variant="contained" color="primary" onClick={this.handleAgregar} >Agregar Firmware</Button>
	    );
	}
    }
}

export default AgregarFirmware;
