import React, {Component} from 'react';
import TypoGraphy from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';

import moment from 'moment';

const dateFormatter = tiempo => {
  return moment.unix(tiempo).format('DD/MM/YY HH:mm');
};

class DatosDelDispositivo extends Component {

    state = {
	estaAjax:false,
	variables:[],
	variableSeleccionada:""
    }


    handleSetVariableSeleccionada= (evento)=> {
	this.setState({variableSeleccionada:evento.target.value});
    }

    handleBajarXLS = (event) => {
	event.preventDefault();
	const {dispositivo}=this.props;
	this.setState({estaAjax:true});

	fetch("/api/portal/datosEnExcel?"+ new URLSearchParams({idDispositivo:dispositivo}),{
	    headers:{
		Authorization:"Bearer "+localStorage.getItem("token")
	    }
	}
	     )
	    .then(res=>res.blob())
	    .then((resultado) => {
		this.setState({estaAjax:false});
		let urlArchivo = window.URL.createObjectURL(resultado);
		const link = document.createElement('a');
		link.href = urlArchivo;
		link.setAttribute('download', "datos.xlsx");
		document.body.appendChild(link);
		link.click();
		link.remove();
		
	    },
		  (error) => {
		      this.setState({estaAjax:false});
		  });
	
    }
    
    obtenerDatos() {
    	//Voy a obtener los puntos de la última semana
	//Y eso se va a graficar
	const {dispositivo}=this.props;

	fetch("/api/portal/nombreVariablesDeDispositivo?"+ new URLSearchParams({idDispositivo:dispositivo}),{
	    headers:{
		Authorization:"Bearer "+localStorage.getItem("token")
	    }
	}
	     )
	    .then(res=>res.json())
	    .then((resultado) => {
		this.setState({
		    variables:resultado
		});

		if(resultado.length>0) {

		    this.setState({
			variableSeleccionada:resultado[0],
		    });


		    //Inicializo los datos para cada variable con vacío
		    resultado.forEach((nombreVariable)=> {
			this.setState({
			    [nombreVariable]:[]
			});
		    });
		    
		    //Para cada variable debo obtener los datos
		    resultado.forEach((nombreVariable)=> {

			fetch("/api/portal/datosParaVariable?"+ new URLSearchParams({idDispositivo:dispositivo,nombreVariable:nombreVariable,limite:3000}),{
			    headers:{
				Authorization:"Bearer "+localStorage.getItem("token")
			    }
			}
			     )
			    .then(res=>res.json())
			    .then((resultado) => {
				this.setState({
				    [nombreVariable]:resultado
				});
			    },
				  (error) => {
				      ;
				  });
		    });
		    
		}
	    }
		  ,
		  (error) => {
		      ;
		  });
	
    }
    
    componentDidUpdate(prevProps) {
	if (this.props.dispositivo !== prevProps.dispositivo) {
	    this.obtenerDatos();
	}
    }
    
    
    render() {

	const {estaAjax,variables,variableSeleccionada}=this.state;
	const {dispositivo}=this.props;

	if(variables==null||variableSeleccionada===""||dispositivo==null) {
	    return null;
	} else {

	    const datos=this.state[variableSeleccionada];

	    if(typeof datos === 'undefined') {
		return null;
	    }
	    
            return (
		<Grid container spacing={3}>
		    
		    <Grid item xs={12} md={4} lg={3}>
			<Card>
			    
			    <CardContent>
				<TypoGraphy component="h2" variant="h6" color="primary" gutterBottom>
				    Selección de variable
				</TypoGraphy>
				
				<TypoGraphy component="h2" variant="subtitle1" color="secondary"> 
				    <InputLabel id="labelSeleccionVariable">Seleccione la variable que desea visualizar</InputLabel>
				    <Select
					labelId="labelSeleccionVariable"
					id="idSeleccionVariable"
					value={variableSeleccionada}
					onChange={this.handleSetVariableSeleccionada}
				    >
					{variables.map((v) => (
					    <MenuItem value={v}>{v}</MenuItem>
					))}
					
				    </Select>
				</TypoGraphy>
			    </CardContent>
			</Card>
		    </Grid>
		    
		    <Grid item xs={12} md={8} lg={9}>
			<Card>
			    <CardContent>
				<TypoGraphy component="h2" variant="h6" color="primary" gutterBottom>
				    Visualización de todos los datos a la fecha.
				</TypoGraphy>
				
				<ResponsiveContainer width="100%" height={300}>
				    <LineChart
					height={300}
					data={datos}
					margin={{
					    top: 5,
					    right: 30,
					    left: 20,
					    bottom: 5,
					}}
				    >
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="Tiempo"  tickFormatter={dateFormatter} />
					<YAxis />
					<Tooltip labelFormatter={dateFormatter}/>
					<Legend />
					<Line type="monotone" dataKey="Valor" stroke="#8884d8" activeDot={{ r: 8 }} />
				    </LineChart>
				</ResponsiveContainer>
				
			    </CardContent>
			</Card>
		    </Grid>
		    
		    <Grid item xs={12} md={6} lg={6}>
			<Card>
			    
			    <CardContent>
				<TypoGraphy component="h2" variant="h6" color="primary" gutterBottom>
				    Últimos 50 elementos
				</TypoGraphy>
				
				<Table size="small">
				    <TableHead>
					<TableRow>
					    <TableCell>Tiempo</TableCell>
					    <TableCell>{variableSeleccionada}</TableCell>
					</TableRow>
				    </TableHead>
				    <TableBody>
					{datos.slice(-50).map((d) => (
					<TableRow key={d.Tiempo} >
					    <TableCell>{dateFormatter(d.Tiempo)}</TableCell>
					    <TableCell>{d.Valor}</TableCell>
					</TableRow>
					))}
				    </TableBody>
				</Table>
			    </CardContent>
			</Card>
		    </Grid>

		    
		    <Grid item xs={12} md={6} lg={6}>
			<Card>
			    <CardContent>
				<TypoGraphy component="h2" variant="h6" color="primary" gutterBottom>
				    Bajar todos los datos
				</TypoGraphy>

				
				<Button variant="contained" color="primary" disabled={estaAjax} onClick={this.handleBajarXLS}>Bajar datos del dispositivo
				    
				</Button>
				{estaAjax?<CircularProgress />:""}
			    </CardContent>
			</Card>
			
		    </Grid>
		    
		</Grid>
	    );
	}
    }
}


export default DatosDelDispositivo;
